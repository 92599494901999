

function Comprar() {
    return (
        <div className='comprar'>

            <div className='texto'>
                <h1>Transforme Sua Vida: Aprenda a Gerar <span>RENDA EXTRA ONLINE</span> Através de um Manual!</h1>
                <p>Com apenas algumas minutos de estudo, veja seu bolso crescer!</p>
                <a href='https://pay.kiwify.com.br/x10kFzQ'>
                    <button className='comprar1'>Comece a GANHAR Agora!</button>
                </a>
                <div>
                    <img src='PAGAMENTO.png' />
                </div>
            </div>
            <div className='imagem'>
                <img src='image1.png' />
            </div>
        </div>
    )
}

export default Comprar;