import "./Introducao.css";

function Introducao() {
    return (
        <>
            <div className="introducao">
                <img src="sentido.png" />
                <br />
                <ul>
                    <li>O dinheiro não sobra no fim do mês?</li>
                    <li>Sente que está sempre um passo atrás das oportunidades financeiras?</li>
                    <li>Está cansado de ver outros avançarem enquanto você fica estagnado?</li>
                    <li>Tem dificuldade em poupar dinheiro para emergências ou sonhos futuros?</li>
                    <li>Preocupado com o futuro financeiro de sua família?</li>
                    <li>Sente que não entende o suficiente sobre investimentos e poupança?</li>
                    <li style={{ paddingBottom: "100px" }}>Quer aumentar sua renda, mas não sabe por onde começar?</li>
                </ul>
            </div>
        </>
    )
}

export default Introducao;
