import "./Incio.css";

function Incio() {
    return (
        <>
            <div className="conteudo-incio">
                <div className="texto-incio">
                    <h1>Descubra o Caminho para a Liberdade Financeira</h1>
                    <h3>Independentemente de ser um novato ou já experiente no mundo digital, nosso guia é a chave para diversificar e fortalecer suas fontes de renda.</h3>
                    <br />
                    <br />
                    <br />
                    <h3>Deixe de perder tempo em busca incerta no YouTube. Aqui você encontra um manual prático e direcionado para o sucesso financeiro.</h3>
                    <br />
                    <br />
                    <br />
                    <h1>As oportunidades que revelamos não exigem investimentos iniciais</h1>
                    <br/>
                    <h1>apenas seu <span className="alert">TEMPO</span> e <span className="alert">DEDICAÇÃO</span>!</h1>
                    </div>
            </div>
        </>
    )
}

export default Incio;