
import "./Bons.css";

function Bons({ imagem, titulo, descricao, direction }) {
    return (
        <>
            <div className="conteudo" style={{ direction: direction }}>
                <div className="bloco">
                    <img src={imagem} />
                </div>

                <div className="bloco texto-bons">
                    <h1 style={{ color: "white" }}>{titulo}</h1>
                    <h3>{descricao}</h3>
                </div>
            </div>

        </>
    )
}

export default Bons;