import './App.css';
import Bons from './Bons/Bons';
import Comprar from './Comprar/Comprar';
import Garantia from './Garantia/Garantia';
import Incio from './Inicio/Incio';
import Introducao from './Introducao/Introducao';
import Oferta from './Oferta/Oferta';
import Timer from './Timer/Timer';

function App() {
  return (
    <div className="App">
      <header>
        {/* <img src="./25333151.png" /> */}
        <Timer />
      </header>

      <Comprar />

      <Oferta />

      <Incio />
      
      <Introducao />

      <Bons imagem={"imagem1.png"} titulo={"Variedade de Oportunidades"} descricao={"De freelancing a marketing digital, explore 40 métodos diferentes e encontre os que mais se alinham com suas habilidades e interesses."} />
      <Bons direction={"rtl"} imagem={"imagem2.png"} titulo={"Passo a Passo Prático"} descricao={"Cada método é detalhado com instruções claras e práticas, tornando fácil começar a aplicar imediatamente."} />
      <Bons imagem={"imagem3.png"} titulo={"Flexibilidade e Liberdade"} descricao={"Aprenda a criar fontes de renda que se encaixam no seu estilo de vida, permitindo mais liberdade e controle sobre seu tempo e finanças."} />

      <Garantia titulo={"Garantia de 7 Dias Satisfação Total ou Seu Dinheiro de Volta"} descricao={"Com a compra do eBook, você recebe nossa garantia de satisfação absoluta de 7 dias. Entendemos a importância de investir em seu crescimento com confiança e tranquilidade."} />

      <Oferta />
    </div>
  );
}

export default App;
