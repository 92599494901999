
import "./Garantia.css";

function Garantia({ imagem, titulo, descricao, direction }) {
    return (
        <>
            <div className="conteudo-garantia" style={{ direction: direction }}>
                <div className="bloco">
                    <img src={"garantia.png"} />
                </div>

                <div className="bloco texto-bons">
                    <h1 style={{ color: "#DC3545" }}>{titulo}</h1>
                    <br />
                    <h3>{descricao}</h3>
                    <br /><br /><br />
                    <h3> Acreditamos no valor que nosso eBook pode agregar à sua vida, por isso oferecemos essa garantia para que você possa fazer sua escolha sem riscos.</h3>
                    <br /><br /><br />
                </div>
            </div>

        </>
    )
}

export default Garantia;