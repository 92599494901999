

function Oferta() {
    return (
        <div className='oferta'>
            <div className='texto1'>
                <h1 className='tudo-isso'>TUDO ISSO</h1>
                <h1 className='apenas'>POR APENAS</h1>
            </div>
            <div className='oferta1'>
                <div className='oferta-cortada'>
                    <h2>de R$ 50</h2>
                    <p>por</p>
                </div>
                <div className='oferta-real'>
                    <h2>27</h2>
                    <p>,99</p>
                </div>
            </div>
            <div className='botao2'>
                <div className='botao-compra'>
                    <a href='https://pay.kiwify.com.br/x10kFzQ'>
                        <button>
                            Garanta Meu DESCONTO Agora!
                        </button>
                    </a>
                    <p>Pagamento único, ACESSO É VITALÍCIO!</p>
                </div>
            </div>
        </div>
    )
}

export default Oferta;