import React, { useState, useEffect } from 'react';
import "./Timer.css";

function Timer() {
  const [time, setTime] = useState(10 * 60); // 10 minutos em segundos

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prevTime => prevTime > 0 ? prevTime - 1 : 0);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="timer">
      {formatTime()} <span className='oferta-45'>Oferta de 45%</span>
    </div>
  );
}

export default Timer;
